@media screen and (max-width: 768px) {
  .eachDetail {
    width: 100%;
    height: 80vh;
  }

  .detailImg_img {
    margin-top: 20px;
  }

  .detailImg_btn {
    width: 100%;
    position: absolute;
    top: 22%;
  }

  .eachBtn {
    padding: 14px 7px;
    font-size: var(--font-small);
  }

  .detailImg_dot {
    display: none;
  }

  .detailPaper {
    margin: 20px;
    line-height: 16px;
  }

  .eachDetail_out {
    width: 20px;
    height: 20px;
    margin-top: 0px;
  }

  .detailPaper .eachPaper {
    margin: 20px 0px;
  }

  .detailPaper_title h1 {
    font-size: var(--font-regular);
  }

  .detailPaper h3 {
    text-align: right;
    font-size: var(--font-small);
  }

  .detailPaper p {
    font-size: var(--font-small);
  }
}

@media screen and (max-width: 558px) {
  .eachDetail_out i {
    font-size: var(--font-min);
    transform: translate(-2%, 0%);
  }
}

@media screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {}