.omma_reck_each {
  height: 100%;
  padding: 0px 8px;
}
.omma_reck_each_print {
  height: 25%;
  padding-right: 4px;
}
.omma_thumbnail_black {
  height: 16vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--color-dddarkgray);
}
.omma_thumbnail_white {
  height: 16vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  padding-right: 20px;
  background-color: var(--color-darkgray);
}
.omma_print_thumbnail_each {
  height: 35%;
  margin-left: 0px;
  margin-right: 6px;
}
