/* eter_spreadH */
.eter_spreadH {
  display: none;
}

/* section_reck_eter */

/* spreadWindow */
.spreadWindow {
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.spreadWindow::-webkit-scrollbar {
  display: none;
}

/* 얘가 캡쳐 됨 */
.grid_container {
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray);
}

.grid_container:hover .grid_box {
  background-color: rgb(225, 216, 206);
  border: solid var(--color-gray) 1px;
  transition: all 2s ease;
}

.grid_container_row {
  display: flex;
  flex-direction: row;
}

.grid_box {
  width: 70px;
  height: 105px;
  margin: 2px 6px;
  cursor: pointer;
  border: solid var(--color-line) 1px;
  transition: all 1.2s ease;
}

.img_btn_box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drag_img {
  width: 100%;
  height: 100%;
}

.img_btn_reck {
  position: absolute;
  top: 76px;
  opacity: 0;
}

.img_btn_box:hover .img_btn_reck {
  width: 120%;
  display: flex;
  justify-content: space-between;
  opacity: 0.84;
  transition: all 0.6s ease;
}

.img_btn {
  padding: 3px 6px;
  margin: 0px 2px;
  border: none;
  border-radius: 5rem;
  background-color: var(--color-white);
  cursor: pointer;
}



/* showWindow */
.showWindow {
  height: 70vh;
  display: flex;
  align-items: center;
  overflow-x: scroll;
}

.showWindow::-webkit-scrollbar {
  display: none;
}

.total8_grid_container {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 76px 76px;
  grid-template-rows: 114px 114px 114px 114px;
  margin: 0px 28px;
}

.total12_grid_container {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 76px 76px 76px;
  grid-template-rows: 114px 114px 114px 114px;
  margin: 0px 28px;
}

.total16_grid_container {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 76px 76px 76px 76px;
  grid-template-rows: 114px 114px 114px 114px;
  margin: 0px 28px;
}

.total20_grid_container {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 76px 76px 76px 76px 76px;
  grid-template-rows: 114px 114px 114px 114px;
  margin: 0px 28px;
}

.total21_grid_container {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 76px 76px 76px 76px 76px 76px 76px;
  grid-template-rows: 114px 114px 114px 114px;
  margin: 0px 28px;
}

.total24_grid_container {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 76px 76px 76px 76px 76px 76px;
  grid-template-rows: 114px 114px 114px 114px;
  margin: 0px 28px;
}

.total32_grid_container {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 76px 76px 76px 76px 76px 76px 76px 76px;
  grid-template-rows: 114px 114px 114px 114px;
  margin: 0px 28px;
}

.total36_grid_container {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 76px 76px 76px 76px 76px 76px 76px 76px 76px;
  grid-template-rows: 114px 114px 114px 114px;
  margin: 0px 28px;
}

.total40_grid_container {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 76px 76px 76px 76px 76px 76px 76px 76px 76px 76px;
  grid-template-rows: 114px 114px 114px 114px;
  margin: 0px 28px;
}

.total44_grid_container {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 76px 76px 76px 76px 76px 76px 76px 76px 76px 76px 76px;
  grid-template-rows: 114px 114px 114px 114px;
  margin: 0px 28px;
}

.dragablImg {
  width: 76px;
  height: 114px;
  position: relative;
  cursor: move;
}

/* SwitchTakeSaveBtn */
.eter_btns {
  height: 7vh;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.eter_btns .eter_btn {
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border: none;
  background-color: var(--color-white);
  border-radius: 5rem;
  cursor: pointer;
}

.eter_btns .eter_btn:hover {
  transform: scale(1.16);
  transition: all 200ms ease-in-out;
}

.eter_btns .eter_btn .push {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -24%;
  left: 64%;
  padding: 2px;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 5rem;
  background-color: var(--color-red);
  color: var(--color-white);
}

.fontAwesome {
  font-size: var(--font-regular);
  color: var(--color-black);
}


/* ClickedReck */
.clicked_reck {
  height: 14vh;
  min-height: 105px;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  overflow-x: scroll;
  padding: 0px 20px;
  background-color: var(--color-darkgray);
}

.clicked_reck::-webkit-scrollbar {
  display: none;
}

.clicked_img {
  width: 70px;
  height: 105px;
  margin: 0px 4px;
}