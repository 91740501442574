@media screen and (max-width: 768px) {
  /* ------------------------------------section_reck */
  .img_index{
    height: 100%;
    position: relative;
    margin: 10px 0px;
  }
  .works_exhi_index1{
    width: 60vw;
    height: 100%;
    margin: 0px;
  }
  /* ------------------------------------Works_info */
  .works_info {
    width: 90vw;
    flex-direction: column;
    justify-content: space-between;
    margin: 0px 0px;
  }
  .works_info .title {
    line-height: 14px;
  }
  .title_title {
    margin-bottom: 6px;
    font-size: var(--font-regular);
    font-weight: var(--weight-bold);
  }
  .title_detail {
    font-size: var(--font-min);
  }
  .works_info .contact {
    font-size: var(--font-min);
  }
}
