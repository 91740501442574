.exhibi_reck {
  height: 86vh;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.exhibi_reck::-webkit-scrollbar {
  display: none;
}

.eachExhibi {
  width: 530px;
  height: 100%;
  margin: 0px 30px;
}

.eachExhibi_img_reck {
  position: relative;
}

.eachExhibi_img {
  width: 530px;
}

.eachExhibi_btn {
  width: 530px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  top: 52%;
  transform: translate(0%, -50%);
}

.btn {
  padding: 14px 7px;
  font-size: var(--font-medium);
  color: var(--color-gray);
  border: none;
  background-color: transparent;
  transition: 0.6s ease;
  cursor: pointer;
}

.btn:hover {
  background-color: var(--color-darkgray);
}

.eachExhibi_Paper {
  height: 360px;
  margin: 30px 0px;
  overflow-y: scroll;
  line-height: 24px;
  padding-bottom: 16px;
}

.eachExhibi_Paper::-webkit-scrollbar {
  display: none;
}

.eachExhibi_Paper .eachDesc {
  margin-bottom: 50px;
}

.eachExhibi_Paper h1 {
  font-size: var(--font-big);
  /* font-family: var(--typo-nanum); */
  font-weight: var(--weight-Extra-bold);
  padding-top: 6px;
  margin-bottom: 30px;
}

.eachExhibi_Paper h3 {
  font-size: var(--font-regular);
  font-weight: var(--weight-Extra-bold);
  margin-bottom: 20px;
}

.eachExhibi_Paper p {
  font-size: var(--font-medium);
}

.eachExhibi_Paper .quote {
  font-size: var(--font-small);
  font-weight: var(--weight-light);
  font-style: oblique;
}

.eachExhibi_Paper .sep {
  border: solid 0.5px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.eachExhibi_Paper .ref {
  font-size: var(--font-min);
  line-height: 20px;
}