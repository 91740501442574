.eachDetail {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;
  height: 600px;
  overflow-y: scroll;
  background-color: var(--color-gray);
  z-index: 2;
}

.eachDetail::-webkit-scrollbar {
  display: none;
}

.detailImg {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detailImg_img {
  width: 100%;
}

.detailImg_btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* position: relative;
  top: -220px; */
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.eachBtn {
  padding: 20px 10px;
  font-size: var(--font-max);
  color: var(--color-gray);
  border: none;
  background-color: transparent;
  transition: 0.6s ease;
  cursor: pointer;
}

.eachBtn:hover {
  background-color: var(--color-darkgray);
}

.detailImg_dot {
  position: absolute;
  top: 86%;
}

.detailImg_dot .dot {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  border: none;
  border-radius: 1rem;
  transition: 0.3s ease;
  background-color: var(--color-gray);
}

.detailPaper {
  margin: 30px;
  line-height: 24px;
}

.detailPaper_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.eachDetail_out {
  width: 30px;
  height: 30px;
  border: none;
  background-color: var(--color-white);
  border-radius: 5rem;
  cursor: pointer;
  margin-top: -20px;
}

.eachDetail_out:hover {
  transform: scale(1.16);
  transition: all 200ms ease-in-out;
}

.detailPaper .eachPaper {
  margin: 30px 0px;
}

.detailPaper h1 {
  font-size: var(--font-max);
  font-weight: var(--weight-Extra-bold);
  margin-top: 6px;
}

.detailPaper h3 {
  margin-bottom: 20px;
  text-align: right;
}

.detailPaper p {
  margin-bottom: 20px;
  line-height: 26px;
}

.detailPaper .quote {
  font-size: var(--font-small);
  font-weight: var(--weight-light);
  font-style: oblique;
}

.detailPaper .sep {
  border: solid 0.5px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.detailPaper .ref {
  font-size: var(--font-min);
  line-height: 20px;
}

.eachDetail_back {
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: var(--color-dddarkgray);
  opacity: 70%;
  -webkit-opacity: 0.7;
  z-index: 1;
}