.each_group {
  height: 100%;
  padding: 0px 8px;
}
.group_img {
  height: 100%;
}
.group_img_s {
  height: 70%;
}
