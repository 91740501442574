@media screen and (max-width: 768px) {
  /* eter_spreadH */

  /* spreadWindow */
  .spreadWindow {
    width: 90vw;
    align-items: flex-start;
    overflow: scroll;
  }

  .grid_box {
    width: 60px;
    height: 90px;
    margin: 2px 4px;
  }

  .img_btn_reck {
    position: absolute;
    top: 60px;
    opacity: 0;
  }

  .img_btn_box:hover .img_btn_reck {
    width: 120%;
    display: flex;
    justify-content: space-between;
  }

  .img_btn {
    padding: 2px 4px;
    margin: 0px 1px;
    font-size: var(--font-min);
  }



  /* showWindow */
  .showWindow {
    width: 90vw;
  }

  .total8_grid_container {
    display: grid;
    grid-gap: 3px;
    grid-template-columns: 60px 60px;
    grid-template-rows: 90px 90px 90px 90px;
    margin: 0px 14px;
  }

  .total12_grid_container {
    display: grid;
    grid-gap: 3px;
    grid-template-columns: 60px 60px 60px;
    grid-template-rows: 90px 90px 90px 90px;
    margin: 0px 14px;
  }

  .total16_grid_container {
    display: grid;
    grid-gap: 3px;
    grid-template-columns: 60px 60px 60px 60px;
    grid-template-rows: 90px 90px 90px 90px;
    margin: 0px 14px;
  }

  .total20_grid_container {
    display: grid;
    grid-gap: 3px;
    grid-template-columns: 60px 60px 60px 60px 60px;
    grid-template-rows: 90px 90px 90px 90px;
    margin: 0px 14px;
  }

  .total21_grid_container {
    display: grid;
    grid-gap: 3px;
    grid-template-columns: 60px 60px 60px 60px 60px 60px 60px;
    grid-template-rows: 90px 90px 90px 90px;
    margin: 0px 14px;
  }

  .total24_grid_container {
    display: grid;
    grid-gap: 3px;
    grid-template-columns: 60px 60px 60px 60px 60px 60px;
    grid-template-rows: 90px 90px 90px 90px;
    margin: 0px 14px;
  }

  .total32_grid_container {
    display: grid;
    grid-gap: 3px;
    grid-template-columns: 60px 60px 60px 60px 60px 60px 60px 60px;
    grid-template-rows: 90px 90px 90px 90px;
    margin: 0px 14px;
  }

  .total36_grid_container {
    display: grid;
    grid-gap: 3px;
    grid-template-columns: 60px 60px 60px 60px 60px 60px 60px 60px 60px;
    grid-template-rows: 90px 90px 90px 90px;
    margin: 0px 14px;
  }

  .total40_grid_container {
    display: grid;
    grid-gap: 3px;
    grid-template-columns: 60px 60px 60px 60px 60px 60px 60px 60px 60px 60px;
    grid-template-rows: 90px 90px 90px 90px;
    margin: 0px 14px;
  }

  .total44_grid_container {
    display: grid;
    grid-gap: 3px;
    grid-template-columns: 60px 60px 60px 60px 60px 60px 60px 60px 60px 60px 60px;
    grid-template-rows: 90px 90px 90px 90px;
    margin: 0px 14px;
  }

  .dragablImg {
    width: 60px;
    height: 90px;
  }

  /* SwitchTakeSaveBtn */
  .eter_btns {
    min-height: 36px;
    justify-content: center;
    align-items: center;
  }

  .eter_btns .eter_btn {
    width: 32px;
    height: 32px;
    margin: 0 8px;
  }

  .eter_btns .eter_btn .push {
    width: 16px;
    height: 16px;
  }

  .fontAwesome {
    font-size: var(--font-small);
  }


  /* ClickedReck */
  .clicked_reck {
    width: 100vw;
    min-height: 90px;
    padding: 0px 20px;
  }

  .clicked_img {
    width: 60px;
    height: 90px;
    margin: 0px 3px;
  }
}