/* ------------------------------------section_reck */
.img_index{
  height: 100%;
  position: relative;
}
.works_exhi_index1{
  height: 100%;
  margin: 0px 10px;
  cursor: pointer;
}
/* ------------------------------------Works_info */
.works_info {
  height: 16vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 20px;
}
.works_info .title {
  line-height: 24px;
}
.title_title {
  margin-bottom: 20px;
  font-size: var(--font-max);
  font-weight: var(--weight-Extra-bold);
}
.title_detail {
  font-size: var(--font-regular);
  font-family: var(--typo-nanum);
}
.works_info .contact {
  text-align: right;
  align-self: flex-end;
}
